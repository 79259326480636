<template>
</template>
<script>
import { downAction } from "@/api/index/index"
export default {
       name: "inputVal",
    props:{
    },

    data: () => {
		return {
		}
        
	},
    mounted() {
    },
       methods:{
      open(data,name,historyname,node,status){
          let projectData = JSON.parse(localStorage.getItem("deviceType"))
          let type = typeof(data)
          let iptreg = /(^[-10000-9]{1,15}$)|(^[-10000-9]{1,15}[\.]{1}[-10000-9]{1,4}$)/
          if((type == "string" || type == "number")&& status!="true"){
              this.$prompt('请输入', name, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: iptreg,
          inputErrorMessage: '请输入1-15位的正负数或保留两位的小数',
          inputValue:data,
          distinguishCancelAndClose: true,
          closeOnClickModal:false
        }).then(({value }) => {
          	downAction({
                device:projectData.device,
                mark:historyname,
                node:node,
                value: value,
                cp_type:projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                //  this.$emit('getTreeData',node,historyname,value);
                 this.$message({
                    message: '数据正在下置，请勿重复操作！',
                    type: 'success',
                    duration:4000,
                    offset:400
                  });
              }else{
                this.$message.error('出错了');
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
				})
        }).catch((action) => {
          if(action == "cancel") return this.$message({type: 'info', message: '取消输入'}); 
        });
          }else if(type == "boolean"||status == "true"){
           this.$confirm('是否改变' + name + '的状态', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          distinguishCancelAndClose: true,
        }).then(() => {  
          console.log(status)
          if(!status){
              data = !data  
          }    
           	downAction({
                device:projectData.device,
                mark:historyname,
                node:node,
                value: data,
                cp_type:projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                // this.$emit('getTreeData',node,historyname,data);
                   this.$message({
                    message: '数据正在下置，请勿重复操作！',
                    type: 'success',
                    duration:4000,
                    offset:400
                  });
              }else{
                this.$message.error('出错了');
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
				})
        }).catch((action) => {
          if(action == "cancel") return this.$message({type: 'info', message: '已取消'});           
        });
          }
      },

        closeCompon(){
           this.$emit('sendStatus', 'Security',false)
        },
    } 
}
</script>
<style lang="scss" scoped>

</style>