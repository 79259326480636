<template>
	<div class="total drag" v-draw id="CfbParameter5">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数05_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				引风优化控制模型
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context flex">
			<div class="one">
				<div class="shadow shadow1 flex">
					<div class="column1">F01-SP</div>
					<div class="column2" @click="toIpt(infoList.MCSFENG.FYSP_B1,'F01-SP','FYSP_B1','MCSFENG')">
						{{infoList.MCSFENG.FYSP_B1}}
					</div>
				</div>
				<div class="shadow shadow2">
					<div class="flex float2">
						<div class="button_column1">
							<div class="button">
								<div class="button_word"
									:style="{background:infoList.MCSYCL.SELYF == 1 ? '#2AFC30' : 'red',}"
									@click="toIpt('1', '负压1','SELYF','MCSYCL','true')">
									负压1
								</div>
							</div>
						</div>
						<div class="column3" @click="toCompon(2,'PT124D_B','MCSSOURCE','PT124D_BVV1','炉膛负压1')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT124D_B,'PT124D_B','MCSSOURCE','PT124D_BVV1')">
							{{infoList.MCSSOURCE.PT124D_B}}
						</div>
					</div>
					<div class="flex">
						<div class="button_column1">
							<div class="button">
								<div class="button_word"
									:style="{background:infoList.MCSYCL.SELYF == 2 ? '#2AFC30' : 'red',}"
									@click="toIpt('2', '负压2','SELYF','MCSYCL','true')">
									负压2
								</div>
							</div>
						</div>
						<div class="column3" @click="toCompon(2,'PT125D_B','MCSSOURCE','PT125D_BVV1','炉膛负压2')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT125D_B,'PT125D_B','MCSSOURCE','PT125D_BVV1')">
							{{infoList.MCSSOURCE.PT125D_B}}
						</div>
					</div>
					<div class="flex">
						<div class="button_column1">
							<div class="button">
								<div class="button_word"
									:style="{background:infoList.MCSYCL.SELYF == 4 ? '#2AFC30' : 'red',}"
									@click="toIpt('4', '负压3','SELYF','MCSYCL','true')">
									负压3
								</div>
							</div>
						</div>
						<div class="column3" @click="toCompon(2,'PT126D_B','MCSSOURCE','PT126D_BVV1','炉膛负压3')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT126D_B,'PT126D_B','MCSSOURCE','PT126D_BVV1')">
							{{infoList.MCSSOURCE.PT126D_B}}
						</div>
					</div>
					<div class="flex">
						<div class="button_column1">
							<div class="button">
								<div class="button_word"
									:style="{background:infoList.MCSYCL.SELYF == 8 ? '#2AFC30' : 'red',}"
									@click="toIpt('8', '负压4','SELYF','MCSYCL','true')">
									负压4
								</div>
							</div>
						</div>
						<div class="column3" @click="toCompon(2,'PT127D_B','MCSSOURCE','PT127D_BVV1','炉膛负压4')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT127D_B,'PT127D_B','MCSSOURCE','PT127D_BVV1')">
							{{infoList.MCSSOURCE.PT127D_B}}
						</div>
					</div>
					<div class="flex">
						<div class="column1 float1">F01-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSFENG.TCFY,'F01-TC','TCFY','MCSFENG')">
							{{infoList.MCSFENG.TCFY}}
						</div>
					</div>
					<div class="flex">
						<div class="column1 float1">F01-YZ</div>
						<div class="column2" @click="toIpt(infoList.MCSYCL.FYHZYZ,'F01-YZ','FYHZYZ','MCSYCL')">
							{{infoList.MCSYCL.FYHZYZ}}
						</div>
					</div>
					<div class="flex">
						<div class="column1 float1">F01-PV</div>
						<div class="column3" @click="toCompon(2,'PV','MCSXK__p__XK06','XK06_PVVV1','XK06测量值')"
							@dblclick="Cclick(infoList.MCSXK__p__XK06.PV,'PV','MCSXK__p__XK06','XK06_PVVV1')">
							{{infoList.MCSXK__p__XK06.PV}}
						</div>
					</div>
				</div>
				<div class="shadow shadow3 flex">
					<div class="column1 float1">F02-SP</div>
					<div class="column2" @click="toIpt(infoList.MCSFENG.FYSP_B1,'F02-SP','FYSP_B1','MCSFENG')">
						{{infoList.MCSFENG.FYSP_B1}}
					</div>
				</div>
			</div>
			<div class="two flex">
				<div class="num" @click="toCompon(2,'LTPJZ','MCSYCL','LTPJZVV1','炉膛负压均值')"
					@dblclick="Cclick(infoList.MCSYCL.LTPJZ,'LTPJZ','MCSYCL','LTPJZVV1')">
					{{infoList.MCSYCL.LTPJZ}}
				</div>
				<div>
					<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK05 && !infoList.MCSXK__p__XK05.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK05
							? toDetail(1, 'XK05', 'MCSXK__p__XK05', '', '负压挡板调节'): ''">
						X
					</div>
					<div class="buttonx float2" :style="{
							background:infoList.MCSXK__p__XK06 && !infoList.MCSXK__p__XK06.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK06
							? toDetail(1, 'XK06', 'MCSXK__p__XK06', '', '负压变频调节'): ''">
						X
					</div>
				</div>
				<div>
					<div class="button1">
						<div class="button1_word" @click="toIpt(infoList.MCSRPQ.QK04SEL,'按钮','QK04SEL','MCSRPQ')">
							{{infoList.MCSRPQ.QK04SEL ? "前馈投用" : "前馈切除"}}
						</div>
					</div>
					<div class="buttonr float3" :style="{
							background:infoList.MCSRPQ__p__QK04 && !infoList.MCSRPQ__p__QK04.TS
					        ? '#2AFC30'
					        : 'red',}" @click="infoList.MCSRPQ__p__QK04
							    ? toDetail(2,'QK04','MCSRPQ__p__QK04','','送风前馈引风'): ''">
						K
					</div>
				</div>
				<div>
					<div class="shadow shadow1 float4 flex">
						<div class="column1">F01-AV</div>
						<div class="column3" @click="toCompon(2,'AV','MCSXK__p__XK05','XK05_AVVV1','XK05总输出')"
							@dblclick="Cclick(infoList.MCSXK__p__XK05.AV,'AV','MCSXK__p__XK05','XK05_AVVV1')">
							{{infoList.MCSXK__p__XK05.AV}}
						</div>
					</div>
					<div class="shadow shadow2">
						<div class="float5 flex">
							<div class="column1">F03-DLTC</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.DLLB,'F03-DLTC','DLLB','MCSFENG')">
								{{infoList.MCSFENG.DLLB}}
							</div>
						</div>
						<div class="float5 flex">
							<div class="column1">F03-DLPV1</div>
							<div class="column3" @click="toCompon(2,'II_YFJ1_B','MCSSOURCE','II_YFJ1_BVV1','1#引风机电流')"
								@dblclick="Cclick(infoList.MCSSOURCE.II_YFJ1_B,'II_YFJ1_B','MCSSOURCE','II_YFJ1_BVV1')">
								{{infoList.MCSSOURCE.II_YFJ1_B}}
							</div>
						</div>
						<div class="flex">
							<div class="button2">
								<div class="button2_word"
									@click="toIpt(infoList.MCSRPQ.RSF0HSEL,'按钮','RSF0HSEL','MCSRPQ')">
									{{infoList.MCSRPQ.RSF0HSEL ? "电流纠偏投用" : "电流纠偏切除"}}
								</div>
							</div>
							<div class="buttonr float6" :style="{
								background:infoList.MCSRPQ__p__RSF0H && !infoList.MCSRPQ__p__RSF0H.SP
							    ? '#2AFC30'
						    	: 'red',}" @click="infoList.MCSRPQ__p__RSF0H
								? toDetail(3,'RSF0H','MCSRPQ__p__RSF0H','','引风机电流纠偏'): ''">
								R
							</div>
						</div>
						<div class="float5 flex">
							<div class="column1">F03-DLPV2</div>
							<div class="column3" @click="toCompon(2,'II_YFJ2_B','MCSSOURCE','II_YFJ2_BVV1','2#引风机电流')"
								@dblclick="Cclick(infoList.MCSSOURCE.II_YFJ2_B,'II_YFJ2_B','MCSSOURCE','II_YFJ2_BVV1')">
								{{infoList.MCSSOURCE.II_YFJ2_B}}
							</div>
						</div>
					</div>
					<div class="shadow shadow1 float7 flex">
						<div class="column1">F02-AV2</div>
						<div class="column3" @click="toCompon(2,'AV','MCSXK__p__XK06','XK06_AVVV1','XK06总输出')"
							@dblclick="Cclick(infoList.MCSXK__p__XK06.AV,'AV','MCSXK__p__XK06','XK06_AVVV1')">
							{{infoList.MCSXK__p__XK06.AV}}
						</div>
					</div>
				</div>
				<div class="pz">
					<div class="pz1">PZ1</div>
					<div class="pz2">PZ2</div>
					<div class="pz3">PZ3</div>
					<div class="pz4">PZ4</div>
				</div>
				<div class="buttonx float8" :style="{
						background:
						infoList.MCSMAN__p__MAN19.RM == 1 || infoList.MCSMAN__p__MAN20.RM == 1 || infoList.MCSMAN__p__MAN21.RM == 1 || infoList.MCSMAN__p__MAN22.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANYF',4)">A</div>
			</div>
			<div class="three">
				<div class="shadow shadow1 float1 flex">
					<div class="column1">F01-A01</div>
					<div class="column3" @click="toCompon(2,'YFDBAO1_B','MCSAO','YFDBAO1_BVV1','1#引风挡板调节输出')"
						@dblclick="Cclick(infoList.MCSAO.YFDBAO1_B,'YFDBAO1_B','MCSAO','YFDBAO1_BVV1')">
						{{infoList.MCSAO.YFDBAO1_B}}
					</div>
				</div>
				<div class="shadow shadow1 float2 flex">
					<div class="column1">F01-A02</div>
					<div class="column3" @click="toCompon(2,'YFDBAO2_B','MCSAO','YFDBAO2_BVV1','2#引风挡板调节输出')"
						@dblclick="Cclick(infoList.MCSAO.YFDBAO2_B,'YFDBAO2_B','MCSAO','YFDBAO2_BVV1')">
						{{infoList.MCSAO.YFDBAO2_B}}
					</div>
				</div>
				<div class="shadow shadow1 float3 flex">
					<div class="column1">F02-A01</div>
					<div class="column3" @click="toCompon(2,'YFBPAO1_B','MCSAO','YFBPAO1_BVV1','1#引风变频阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.YFBPAO1_B,'YFBPAO1_B','MCSAO','YFBPAO1_BVV1')">
						{{infoList.MCSAO.YFBPAO1_B}}
					</div>
				</div>
				<div class="shadow shadow1 float4 flex">
					<div class="column1">F02-A02</div>
					<div class="column3" @click="toCompon(2,'YFBPAO2_B','MCSAO','YFBPAO2_BVV1','2#引风变频阀位输出')"
						@dblclick="Cclick(infoList.MCSAO.YFBPAO2_B,'YFBPAO2_B','MCSAO','YFBPAO2_BVV1')">
						{{infoList.MCSAO.YFBPAO2_B}}
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import manyManual from "@/components/manyManual.vue"; //手操器组件
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			manyManual
		},
		data: () => {
			return {
				chName: '',
				manyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter05', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
				}
			},
			toIpt(data, name, historyname, node, type) {
				this.$refs.inputVal.open(data, name, historyname, node, type)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter5 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 51.1vw;
			height: 31vh;
			margin-top: 3vh;
			margin-left: 1.1vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.2vh;
				line-height: 2.5vh;

				.column1 {
					width: 4vw;
					color: #8aeaff;
					margin-left: 0.3vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.one {
				.shadow1 {
					width: 7.3vw;
					height: 2.2vh;
					margin-top: 4.7vh;

					.column1 {
						margin-left: 0.7vw;
					}
				}

				.shadow2 {
					width: 7.3vw;
					height: 21.2vh;
					margin-top: 2.7vh;

					.float2 {
						margin-top: 1vh;
					}

					.button_column1 {
						width: 4.75vw;

						.button {
							width: 3.1vw;
							height: 2.6vh;
							background-color: #22fff7;
							border-radius: 1px;
							margin-top: 0.3vh;
							margin-left: 0.5vw;
							text-align: center;

							.button_word {
								font-size: 1.5vh;
								line-height: 2.8vh;
								color: #0a4c62;
							}
						}
					}

					.float1 {
						margin-left: 0.7vw;
					}
				}

				.shadow3 {
					width: 7.3vw;
					height: 2.2vh;
					margin-top: 2.4vh;

					.column1 {
						margin-left: 0.7vw;
					}
				}
			}

			.two {
				width: 35.7vw;
				height: 40.8vh;
				background-image: url("~@/assets/images/CfbBoiler/para051.png");
				background-size: 100% 100%;

				.num {
					font-size: 1.4vh;
					line-height: 1.9vh;
					color: #2fc3e3;
					margin-top: 17.6vh;
					margin-left: 1vw;
				}

				.float1 {
					margin-left: 0.3vw;
					margin-top: 5vh;
				}

				.float2 {
					margin-left: 0.3vw;
					margin-top: 26vh;
				}

				.button1 {
					width: 3.5vw;
					height: 1.9vh;
					background-color: #22fff7;
					border-radius: 1px;
					text-align: center;
					margin-left: 3.3vw;
					margin-top: 16.0vh;

					.button1_word {
						font-size: 1.2vh;
						line-height: 1.8vh;
						color: #0a4c62;
					}
				}

				.float3 {
					margin-left: 4.2vw;
					margin-top: 0.5vh;
				}

				.shadow1 {
					width: 7.3vw;
					height: 2.2vh;
				}

				.float4 {
					margin-left: 4.2vw;
					margin-top: 4.9vh;
				}

				.shadow2 {
					width: 8.3vw;
					height: 11.1vh;
					margin-left: 3.0vw;
					margin-top: 5.8vh;

					.float5 {
						margin-left: 1vw;
					}

					.button2 {
						width: 5.5vw;
						height: 2.5vh;
						border-radius: 1px;
						border: solid 1px #0bd0c9;
						text-align: center;
						margin-left: 0.3vw;

						.button2_word {
							font-size: 1.2vh;
							line-height: 2.4vh;
							color: #22fff7;
						}
					}

					.float6 {
						margin-left: 0.5vw;
					}
				}

				.float7 {
					margin-top: 9.2vh;
					margin-left: 4.0vw;
				}

				.pz {
					font-size: 1vh;
					line-height: 3vh;
					color: #2fc3e3;
					margin-left: 4.8vw;

					.pz1 {
						margin-top: 0.3vh;
					}

					.pz2 {
						margin-top: 5.1vh;
					}

					.pz3 {
						margin-top: 18vh;
					}

					.pz4 {
						margin-top: 5.1vh;
					}
				}

				.float8 {
					margin-top: 19vh;
					margin-left: 2.8vw;
				}
			}

			.three {
				.shadow1 {
					width: 7.7vw;
					height: 2.2vh;
				}

				.float1 {
					margin-top: 0.8vh;
				}

				.float2 {
					margin-top: 5.3vh;
				}

				.float3 {
					margin-top: 18.8vh;
				}

				.float4 {
					margin-top: 5.5vh;
				}
			}
		}
	}
</style>
